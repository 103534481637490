<template>
  <div class="box">
    <div class="sidebar">
      <sidebar></sidebar>
    </div>
    <div class="declare">
      <Header></Header>
      <div class="declare_cont">
        <div class="title">{{ info.title }}</div>
        <div class="introduce">
          <!-- <div class="name">
            <img src="../assets/wicon_5.png" alt="" />填表说明
          </div> -->
          <div class="word" v-html="info.describe"></div>
        </div>
        <div class="text">
          <!-- <img src="../assets/wicon_6.png" alt="" />{{ info.title }}下载 -->
          <div class="down" @click="download(info.file)">
            <img src="../assets/wicon_6.png" alt="" />下载申报表
          </div>
        </div>
        <!-- <div class="text">
          <img src="../assets/wicon_6.png" alt="" />{{ info.title }}上传
        </div> -->
        <el-upload
          class="upload-demo"
          action="https://www.ic-ceca.org.cn/api/common/upload"
          :headers="{ token: token }"
          :on-success="handleAvatarSuccess"
          :limit="1"
        >
          <div class="but">
            <img src="../assets/wicon_7.png" alt="" />上传申报文件<span
              style="font-size: 12px"
              >(多个文件,压缩后上传)</span
            >
          </div>
        </el-upload>
        <el-button
          @click="projectApply"
          type="primary"
          style="
            margin-top: 50px;
            background-color: #1890ff;
            font-size: 18px;
            width: 200px;
          "
          >提交</el-button
        >
        <div class="relation" v-if="info.name">
          <div class="list">联系人: {{ info.name }}</div>
          <div class="list">电话: {{ info.phone }}</div>
          <div class="list">传 真: {{ info.fax }}</div>
          <div class="list">Email: {{ info.email }}</div>
        </div>
        <!-- <div class="cha"><img src="../assets/wicon_4.png" alt="" /></div> -->
      </div>
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import { projectDetails, projectApply } from "../api/index";
import Header from "../components/header2.vue";
import Footer from "../components/footer2.vue";
import sidebar from "../components/sidebar.vue";
export default {
  data() {
    return {
      token: localStorage.getItem("token"),
      info: {},
      file: "",
    };
  },
  components: {
    Header,
    Footer,
    sidebar,
  },
  props: ["id"],
  mounted() {
    this.projectDetails();
  },
  methods: {
    projectDetails() {
      this.Loading(".declare_cont");
      projectDetails({
        id: this.id,
      }).then((res) => {
        this.isloading.close();
        if (res.data.code == 1) {
          this.info = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    // handleAvatarSuccess(res, file) {
    //上传接口
    //   this.imageUrl = URL.createObjectURL(file.raw);
    // this.projectApply();
    // },
    projectApply() {
      this.Loading(".declare_cont");
      projectApply({
        id: this.id,
        file: this.file,
      }).then((res) => {
        this.isloading.close();
        if (res.data.code == 1) {
          // console.log('66666666',res.data.code)
          this.$message.success("提交成功！");
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    handleAvatarSuccess(res, file) {
      //调用图片上传接口
      // console.log('file',file)
      // console.log('res',res.data.fullurl)
      this.file = res.data.fullurl;
      // this.projectApply();
    },
    download(url) {
      const link = document.createElement("a");
      link.href = "https://www.ic-ceca.org.cn" + url;
      link.download = "单位申报意见表.docx";
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      // window.open(`https://zydz.site-design.cn${url}`)
    },
  },
};
</script>

<style scoped lang="scss">
body {
  padding: 0;
  margin: 0;
}

.box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.sidebar {
  width: 18%;
}

.declare {
  width: 82%;
  background: linear-gradient(
    180deg,
    rgba(171, 198, 246, 0.27) 0%,
    rgba(216, 224, 232, 0.1) 100%
  );
}

.declare_cont {
  height: auto;
  border-radius: 5px;
  background: #fff;
  margin: 35px 72px 91px 82px;
  padding: 47px 101px 67px 101px;
  position: relative;
}

.declare_cont .cha {
  position: absolute;
  top: -15px;
  right: -35px;
}
.declare_cont .title {
  margin-bottom: 50px;
  color: #000;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
}
.declare_cont .introduce {
  padding: 27px 80px 21px 48px;
  border-radius: 20px;
  background: #f6f9ff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
  margin-bottom: 20px;
}
.declare_cont .introduce .name {
  color: #000;
  font-size: 18px;
  font-weight: 500;
  line-height: 190%; /* 34.2px */
  text-transform: lowercase;
}
.declare_cont .introduce .name img {
  margin-right: 10px;
  margin-top: -3px;
  vertical-align: middle;
}
.declare_cont .introduce .word {
  color: #000;
  font-size: 18px;
  line-height: 179%; /* 32.22px */
  text-transform: lowercase;
  margin-left: 30px;
}
.declare_cont .text {
  color: #000;
  font-size: 22px;
  text-transform: lowercase;
  margin-bottom: 10px;
}
.declare_cont .text img {
  margin-right: 10px;
  margin-top: -3px;
  vertical-align: middle;
}
.declare_cont .down {
  color: #1890ff;
  font-size: 18px;
  font-weight: 500;
  text-decoration-line: underline;
  text-transform: lowercase;
  margin-bottom: 10px;
  margin-left: 30px;
}
.declare_cont .but {
  // width: 334px;
  padding: 0 20px;
  height: 50px;
  line-height: 50px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #1890ff;
  text-align: center;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  margin-top: 20px;
}
.declare_cont .but img {
  margin-right: 10px;
  margin-top: -3px;
  vertical-align: middle;
}
.declare_cont .relation {
  border-radius: 20px;
  background: #f6f9ff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
  padding: 40px 148px 40px 195px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 100px;
}

.declare_cont .relation .list {
  width: 50%;
  margin-bottom: 5px;
  color: #000;
  font-size: 17px;
  text-transform: lowercase;
}
@media only screen and (max-width: 1800px) {
  .declare_cont .title {
    margin-bottom: 20px;
    color: #000;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
  }
  .declare_cont .introduce {
    padding: 20px;
    margin-bottom: 20px;
  }
  .declare_cont .introduce .name {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    line-height: 190%;
    text-transform: lowercase;
  }
  .declare_cont .introduce .word {
    color: #000;
    font-size: 16px;
    line-height: 179%;
    text-transform: lowercase;
    margin-left: 30px;
  }
  .declare_cont .text {
    color: #000;
    font-size: 18px;
    text-transform: lowercase;
    margin-bottom: 10px;
  }
  .declare_cont .but {
    width: 244px;
    height: 50px;
    line-height: 50px;
    flex-shrink: 0;
    border-radius: 5px;
    background: #1890ff;
    text-align: center;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    margin-top: 20px;
  }
  .declare_cont {
    height: auto;
    border-radius: 5px;
    background: #fff;
    margin: 35px 72px 0px 82px;
    padding: 47px 101px 67px 101px;
    position: relative;
  }
  .declare_cont .relation {
    border-radius: 20px;
    background: #f6f9ff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
    padding: 40px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 100px;
  }
}

@media only screen and (max-width: 1600px) {
  .declare_cont {
    height: auto;
    margin: 30px 30px 0;
    padding: 30px;
  }
  .declare_cont .cha {
    position: absolute;
    top: -15px;
    right: -15px;
  }
  .declare_cont .relation {
    border-radius: 20px;
    background: #f6f9ff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
    padding: 40px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 20px;
  }
}
@media only screen and (max-width: 768px) {
  .box {
    .sidebar {
      width: 0;
    }
    .declare {
      width: 100%;
      .declare_cont {
        .submit {
          width: auto;
        }
        .relation .list {
          font-size: 14px;
          // padding: 0;
          width: 100%;
        }
      }
    }
  }
}
</style>