<template>
  <div class="form">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      class="demo-ruleForm"
      label-position="top"
      inline="true"
      hide-required-asterisk="true"
      inline-message="true"
    >
      <el-form-item v-if="type != 0" label="用户名" prop="username" required>
        <el-input
          v-model="ruleForm.username"
          placeholder="请输入用户名"
        ></el-input>
      </el-form-item>
      <el-form-item v-if="type != 0" label="密码" prop="password" required>
        <el-input
          v-model="ruleForm.password"
          placeholder="密码"
          type="password"
        ></el-input>
      </el-form-item>
      <el-form-item label="单位名称" prop="company">
        <el-input
          v-model="ruleForm.company"
          placeholder="请输入单位名称"
        ></el-input>
      </el-form-item>
      <!-- <el-form-item label="曾用名称" prop="old_name">
        <el-input
          v-model="ruleForm.old_name"
          placeholder="请输入曾用名称"
        ></el-input>
      </el-form-item> -->
      <!-- <el-form-item label="所属集团" prop="group">
        <el-input
          v-model="ruleForm.group"
          placeholder="请输入所属集团"
        ></el-input>
      </el-form-item> -->
      <el-form-item label="统一社会信用代码(税号)" prop="credit_code">
        <el-input
          v-model="ruleForm.credit_code"
          placeholder="统一社会信用代码(税号)"
        ></el-input>
      </el-form-item>
      <el-form-item label="成立时间" prop="create_time" style="width: 29%">
        <el-date-picker
          v-model="create_time"
          type="date"
          placeholder="选择日期"
          style="width: 100%"
          @change="createhange"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="注册资金（万元）" prop="register_price">
        <el-input
          v-model="ruleForm.register_price"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item label="网址" prop="website">
        <el-input v-model="ruleForm.website" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="员工总数（人）" prop="staff_num">
        <el-input v-model="ruleForm.staff_num" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="技术人员（人）" prop="skill_num">
        <el-input v-model="ruleForm.skill_num" placeholder="请输入"></el-input>
      </el-form-item>

      <div class="title">经营情况</div>
      <div class="bg">
        <el-form-item label="选择年份" prop="revenue_year" class="width-25">
          <el-date-picker
            v-model="revenue_year"
            type="year"
            placeholder="2023"
            style="width: 100%"
            :picker-options="pickerOptions"
            @change="yearchange"
          >
          </el-date-picker>
        </el-form-item>
        <br />
        <el-form-item
          label="营业收入（万元）"
          prop="revenue_price"
          class="width-25"
        >
          <el-input
            v-model="ruleForm.revenue_price"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="出口额（万美元）"
          prop="export_price"
          class="width-25"
        >
          <el-input
            v-model="ruleForm.export_price"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="资产总额(万元)"
          prop="total_price"
          class="width-25"
        >
          <el-input
            v-model="ruleForm.total_price"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
      </div>
      <br />
      <div class="title">注册地址</div>
      <el-form-item label="" prop="" style="width: 62%; margin-top: 20px">
        <el-cascader
          style="width: 100%"
          size="large"
          :options="options"
          v-model="selectedOptions"
          @change="handleChange"
          placeholder="省市区"
        >
        </el-cascader>
      </el-form-item>
      <el-form-item
        label=""
        prop="address"
        style="width: 62%; margin-top: 40px"
      >
        <el-input
          v-model="ruleForm.address"
          placeholder="请输入详细地址"
        ></el-input>
      </el-form-item>
      <el-form-item label="邮编" prop="address_code">
        <el-input
          v-model="ruleForm.address_code"
          placeholder="请输入"
        ></el-input>
      </el-form-item>

      <div class="title">邮寄地址</div>
      <el-form-item label="" prop="" style="width: 62%; margin-top: 20px">
        <el-cascader
          style="width: 100%"
          size="large"
          :options="options"
          v-model="selectedOptions2"
          @change="handleChange2"
          placeholder="省市区"
        >
        </el-cascader>
      </el-form-item>
      <el-form-item label="" prop="email" style="width: 62%; margin-top: 40px">
        <el-input v-model="ruleForm.email" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="邮编" prop="email_code">
        <el-input v-model="ruleForm.email_code" placeholder="请输入"></el-input>
      </el-form-item>

      <div class="title">法定代表人信息</div>
      <el-form-item label="姓名" prop="fr_name">
        <el-input v-model="ruleForm.fr_name" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="政治面貌" prop="fr_type">
        <el-input v-model="ruleForm.fr_type" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="国籍" prop="fr_nationality">
        <el-input
          v-model="ruleForm.fr_nationality"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item label="职务" prop="fr_job">
        <el-input v-model="ruleForm.fr_job" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="电话" prop="fr_phone">
        <el-input v-model="ruleForm.fr_phone" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="e-mail" prop="fr_email">
        <el-input v-model="ruleForm.fr_email" placeholder="请输入"></el-input>
      </el-form-item>

      <div class="title">
        理事代表信息<input
          type="checkbox"
          v-model="sameAsLegalRep"
          @change="samechange"
          style="margin-left: 10px"
        /><span style="font-size: 18px">与法人代表相同可不填写</span>
      </div>
      <el-form-item label="姓名" prop="hy_name">
        <el-input v-model="ruleForm.hy_name" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="出生年月" prop="hy_birthday" style="width: 29%">
        <el-date-picker
          v-model="hy_birthday"
          type="date"
          placeholder="选择日期"
          style="width: 100%"
          @change="birchange"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="政治面貌" prop="hy_type">
        <el-input v-model="ruleForm.hy_type" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="国籍" prop="hy_nationality">
        <el-input
          v-model="ruleForm.hy_nationality"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item label="部门" prop="hy_department">
        <el-input
          v-model="ruleForm.hy_department"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item label="职务" prop="hy_job">
        <el-input v-model="ruleForm.hy_job" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="手机" prop="hy_phone">
        <el-input v-model="ruleForm.hy_phone" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="e-mail" prop="hy_email">
        <el-input v-model="ruleForm.hy_email" placeholder="请输入"></el-input>
      </el-form-item>

      <div class="title">联系人信息</div>
      <el-form-item label="姓名" prop="lxr_name">
        <el-input v-model="ruleForm.lxr_name" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="部门" prop="lxr_department">
        <el-input
          v-model="ruleForm.lxr_department"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item label="职务" prop="lxr_job">
        <el-input v-model="ruleForm.lxr_job" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="单位电话" prop="lxr_tel">
        <el-input v-model="ruleForm.lxr_tel" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="传真" prop="lxr_fax">
        <el-input v-model="ruleForm.lxr_fax" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="手机" prop="lxr_phone">
        <el-input v-model="ruleForm.lxr_phone" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="e-mail" prop="lxr_email">
        <el-input v-model="ruleForm.lxr_email" placeholder="请输入"></el-input>
      </el-form-item>

      <div class="title">单位类别</div>
      <div class="bg">
        <el-form-item label="" prop="company_type" style="width: 100%">
          <el-radio-group v-model="company_type" @input="inputtype">
            <el-radio label="国有控股企业"></el-radio>
            <el-radio label="民营控股企业"></el-radio>
            <el-radio label="社团组织"></el-radio>
            <el-radio label="事业单位"></el-radio>
            <el-radio label="中外合资合作企业 (请在下方注明)"></el-radio>
            <el-radio label="港澳台合资企业 (请在下方注明)"></el-radio>
            <el-radio label="外商独资企业 (请在下方注明)"></el-radio>
            <!-- <br /> -->
            <el-radio label="其他 (请在下方注明)"></el-radio>
          </el-radio-group>
          <el-input v-model="ruleForm.name" style="width: 98%"></el-input>
          <div
            style="
              font-size: 12px;
              padding-left: 20px;
              color: rgb(124, 125, 125);
            "
          >
            如：中国台湾、中国香港、中国澳门、美国、德国、英国、日本、韩国、印度、越南……等
          </div>
        </el-form-item>
      </div>
      <div class="title">主营产品</div>
      <el-form-item label="" prop="product_dis" style="width: 94%">
        <el-input
          type="textarea"
          v-model="ruleForm.product_dis"
          rows="8"
          maxlength="500"
        ></el-input>
      </el-form-item>
      <div class="title">请选择归口部门</div>
      <el-form-item label="" style="width: 94%">
        <el-select
          v-model="ruleForm.belong_department"
          placeholder=""
          style="width: 100%"
        >
          <el-option
            v-for="(item, index) in departmentArr"
            :key="index"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <div class="title">请选择想要参加活动的专业领域</div>
      <el-form-item label="" style="width: 94%">
        <el-select
          v-model="speciality"
          placeholder=""
          style="width: 100%"
          multiple
          @change="moreselect"
        >
          <el-option
            v-for="(item, index) in specialityArr"
            :key="index"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <div v-if="type == 0 || isdownload == true">
        <div class="title center">
          上传附件内容<span v-if="type != 0" style="color: red; font-size: 12px"
            >（请下载申请表，加盖公章后，与贵单位统一社会信用代码证（或营业执照）扫描件一并上传，完成入会申请提交。）</span
          >
        </div>
        <el-form-item
          label="入会申请表"
          style="width: 45%; text-align: center"
          v-loading="loading3"
        >
          <el-upload
            action="https://www.ic-ceca.org.cn/api/common/upload"
            :headers="{ token: token, __token__: token }"
            list-type="picture-card"
            :limit="1"
            :file-list="fileList3"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :on-success="handleAvatarSuccess3"
            :on-progress="handleUploadProgress3"
            :on-error="handleAvatarError3"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>

        <el-form-item
          label="营业执照"
          style="width: 40%; text-align: center"
          v-loading="loading"
        >
          <el-upload
            action="https://www.ic-ceca.org.cn/api/common/upload"
            :headers="{ token: token, __token__: token }"
            list-type="picture-card"
            :limit="1"
            :file-list="fileList"
            :on-success="handleAvatarSuccess"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :on-progress="handleUploadProgress"
            :on-error="handleAvatarError"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>

        <!-- <el-form-item
          label="缴费凭证"
          style="width: 28%; text-align: center"
          v-loading="loading2"
        >
          <el-upload
            action="https://zydz.site-design.cn/api/common/upload"
            :headers="{ token: token, __token__: token }"
            list-type="picture-card"
            :limit="1"
            :file-list="fileList2"
            :on-success="handleAvatarSuccess2"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :on-progress="handleUploadProgress2"
            :on-error="handleAvatarError2"
          >
            <i class="el-icon-plus"></i> -->
        <!-- <el-progress style="position: absolute;" v-if="showProgress" :percentage="uploadPercentage" type="circle" :stroke-width="6"></el-progress> -->
        <!-- </el-upload>
        </el-form-item> -->
      </div>
      <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="" />
      </el-dialog>
      <el-button
        v-if="type == 0 || isdownload == true"
        type="primary"
        @click="submitForm('ruleForm')"
        >提交</el-button
      >
      <a>
        <el-button
          v-if="type != 0 && isdownload == false"
          type="primary"
          @click="exportWord('ruleForm')"
          >提交信息并下载申请表</el-button
        >
      </a>
      <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
    </el-form>
  </div>
</template>
  <script>
import { pcaTextArr } from "element-china-area-data";
import {
  memberApply,
  getOtherSelect,
  getEditMember,
  exportWord,
} from "../api/index";
export default {
  name: "form",
  props: ["type"],
  data() {
    return {
      token: localStorage.getItem("token"),
      loading: false,
      loading2: false,
      loading3: false,
      isdownload: false,
      fileList: [],
      // fileList2: [],
      fileList3: [],
      dialogImageUrl: "",
      dialogVisible: false,
      showProgress: false,
      uploadPercentage: 0,
      id: 0,
      oldcompany: "",
      yyzzfile:'',
      ruleForm2: {},
      ruleForm: {
        username: "",
        password: "",
        company: "",
        // old_name: "",
        // group: "",
        credit_code: "",
        create_time: "",
        register_price: "",
        website: "",
        staff_num: "",
        skill_num: "",
        revenue_year: "",
        revenue_price: "",
        export_price: "",
        total_price: "",
        address: "",
        address_code: "",
        email: "",
        email_code: "",
        province: "",
        city: "",
        area: "",
        email_province: "",
        email_area: "",
        email_city: "",
        fr_name: "",
        fr_type: "",
        fr_nationality: "",
        fr_job: "",
        fr_phone: "",
        fr_email: "",
        hy_name: "",
        hy_birthday: "",
        hy_type: "",
        hy_nationality: "",
        hy_department: "",
        hy_job: "",
        hy_phone: "",
        hy_email: "",
        lxr_name: "",
        lxr_department: "",
        lxr_job: "",
        lxr_tel: "",
        lxr_fax: "",
        lxr_phone: "",
        lxr_email: "",
        company_type: "",
        product_dis: "",
        belong_department: "",
        speciality: "",
        file: "",
        // pay_file: "",
        word_file: "",
      },
      company_type: "",
      sameAsLegalRep: false,
      pickerOptions: {
        disabledDate(date) {
          const now = new Date();
          const currentYear = now.getFullYear() - 1;
          const lastYear = currentYear - 1;
          const year = date.getFullYear();
          return year !== currentYear && year !== lastYear;
        },
      },
      options: pcaTextArr,
      selectedOptions: [],
      selectedOptions2: [],
      create_time: "",
      revenue_year: "",
      hy_birthday: "",
      speciality: "",
      departmentArr: [],
      specialityArr: [],
      rules: {
        company: [{ required: true, message: "必填", trigger: "blur" }],
        // group: [{ required: true, message: "必填", trigger: "blur" }],
        credit_code: [{ required: true, message: "必填", trigger: "blur" }],
        create_time: [{ required: true, message: "必填", trigger: "blur" }],
        website: [{ required: true, message: "必填", trigger: "blur" }],
        // old_name: [{ required: true, message: "必填", trigger: "blur" }],
        address: [{ required: true, message: "必填", trigger: "blur" }],
        address_code: [{ required: true, message: "必填", trigger: "blur" }],
        email: [{ required: true, message: "必填", trigger: "blur" }],
        email_code: [{ required: true, message: "必填", trigger: "blur" }],
        province: [{ required: true, message: "必填", trigger: "blur" }],
        city: [{ required: true, message: "必填", trigger: "blur" }],
        area: [{ required: true, message: "必填", trigger: "blur" }],
        email_province: [{ required: true, message: "必填", trigger: "blur" }],
        email_area: [{ required: true, message: "必填", trigger: "blur" }],
        email_city: [{ required: true, message: "必填", trigger: "blur" }],
        fr_name: [{ required: true, message: "必填", trigger: "blur" }],
        fr_type: [{ required: true, message: "必填", trigger: "blur" }],
        fr_nationality: [{ required: true, message: "必填", trigger: "blur" }],
        fr_job: [{ required: true, message: "必填", trigger: "blur" }],
        fr_phone: [{ required: true, message: "必填", trigger: "blur" }],
        lxr_name: [{ required: true, message: "必填", trigger: "blur" }],
        lxr_department: [{ required: true, message: "必填", trigger: "blur" }],
        lxr_job: [{ required: true, message: "必填", trigger: "blur" }],
        lxr_tel: [{ required: true, message: "必填", trigger: "blur" }],
        lxr_fax: [{ required: true, message: "必填", trigger: "blur" }],
        // company_type: [{ required: true, message: "必填", trigger: "blur" }],
        product_dis: [{ required: true, message: "必填", trigger: "blur" }],
        belong_department: [
          { required: true, message: "必填", trigger: "blur" },
        ],
        speciality: [{ required: true, message: "必填", trigger: "blur" }],
        register_price: [
          {
            required: true,
            pattern: /^(-)?[0-9]+([.][0-9]{1,})?$/,
            message: "请输入数字",
            trigger: "blur",
          },
        ],
        staff_num: [
          {
            required: true,
            pattern: /^[0-9]+$/,
            message: "请输入数字",
            trigger: "blur",
          },
        ],
        skill_num: [
          {
            required: true,
            pattern: /^[0-9]+$/,
            message: "请输入数字",
            trigger: "blur",
          },
        ],
        revenue_year: [{ required: true, message: "必填", trigger: "blur" }],
        revenue_price: [
          {
            required: true,
            pattern: /^(-)?[0-9]+([.][0-9]{1,})?$/,
            message: "请输入数字",
            trigger: "blur",
          },
        ],
        export_price: [
          {
            required: true,
            pattern: /^(-)?[0-9]+([.][0-9]{1,})?$/,
            message: "请输入数字",
            trigger: "blur",
          },
        ],
        total_price: [
          {
            required: true,
            pattern: /^(-)?[0-9]+([.][0-9]{1,})?$/,
            message: "请输入数字",
            trigger: "blur",
          },
        ],
        company: [{ required: true, message: "必填", trigger: "blur" }],
        address_code: [
          {
            required: true,
            pattern: /^(-)?[0-9]+([.][0-9]{1,})?$/,
            message: "请输入数字",
            trigger: "blur",
          },
        ],
        email_code: [
          {
            required: true,
            pattern: /^(-)?[0-9]+([.][0-9]{1,})?$/,
            message: "请输入数字",
            trigger: "blur",
          },
        ],
        total_price: [
          {
            required: true,
            pattern: /^(-)?[0-9]+([.][0-9]{1,})?$/,
            message: "请输入数字",
            trigger: "blur",
          },
        ],
        // fr_phone: [
        //   {
        //     pattern: /^(13[0-9]|14[5|7]|15[0|1|2|3|5|6|7|8|9]|18[0|1|2|3|5|6|7|8|9])\d{8}$/,
        //     message: "电话号码格式不正确",
        //     trigger: "blur",
        //   },
        // ],
        fr_email: [
          {
            required: true,
            pattern: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
            message: "邮箱格式不正确",
            trigger: "blur",
          },
        ],
        // hy_phone: [
        //   {
        //     required: true,
        //     pattern:
        //       /^1((34[0-8])|(8\d{2})|(([35][0-35-9]|4[579]|66|7[35678]|9[1389])\d{1}))\d{7}$/,
        //     message: "电话号码格式不正确",
        //     trigger: "blur",
        //   },
        // ],
        // hy_email: [
        //   {
        //     required: true,
        //     pattern: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
        //     message: "邮箱格式不正确",
        //     trigger: "blur",
        //   },
        // ],
        // lxr_tel: [
        //   {
        //     // pattern: ^(\(\d{3,4}-)|\d{3.4}-)?\d{7,8}$,
        //     message: "单位电话号码格式不正确",
        //     trigger: "blur",
        //   },
        // ],
        lxr_phone: [
          {
            required: true,
            pattern:
              /^1((34[0-8])|(8\d{2})|(([35][0-35-9]|4[579]|66|7[35678]|9[1389])\d{1}))\d{7}$/,
            message: "手机号码格式不正确",
            trigger: "blur",
          },
        ],
        lxr_email: [
          {
            required: true,
            pattern: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
            message: "邮箱格式不正确",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    ruleForm: {
      handler(newValue) {
        // console.log("表单数据发生变化：", newValue);
        this.$store.commit("addInfo", newValue);
        // if (newValue.company != this.oldcompany) {
        //   this.$message({
        //     duration: 6000,
        //     type: "error",
        //     message: "请上传贵单位新的统一社会信用代码证（或营业执照）扫描件",
        //   });
        // }
        // console.log(newValue.company+'========'+this.oldcompany)
      },
      deep: true,
    },
  },
  mounted() {
    this.getOtherSelect();
    if (this.type == 0) {
      localStorage.removeItem("info");
      this.getEditMember();
    } else {
      this.exportWord();
      this.ruleForm = this.$store.state.infoString;
    }
  },
  methods: {
    inputtype(e) {
      let result = e.replace(/\(.*\)/g, "");
      this.ruleForm.company_type = result;
      // console.log(this.ruleForm.company_type);
    },
    // ...mapMutations(["addInfo"]),
    dateFormat(date) {
      date = date * 1000;
      let result = new Date(date);
      return result;
    },
    //生成文档
    exportWord(formName) {
      let that = this;
      const { file, word_file, ...newObj } = that.ruleForm;
      newObj["user"] = localStorage.getItem("uid");
      that.$refs[formName].validate((valid) => {
        if (valid) {
          that.Loading(".form");
          exportWord({
            ...newObj,
          }).then((res) => {
            that.isloading.close();
            // console.log('入会申请',res)
            if (res.data.code == 1) {
              that.isdownload = true;
              // that.$message.success(res.data.msg);
              that.$message.success("提交成功");
              // window.open(res.data.data.url, '_blank');
              const link = document.createElement("a");
              link.href =
                "https://www.ic-ceca.org.cn/" + res.data.data.filename;
              link.download = "入会申请表.docx";
              link.target = "_blank";
              document.body.appendChild(link);
              link.click();
              // console.log(res.data);
              // that.ruleForm = {};
              // setTimeout(() => {
              //   that.$router.push("/success");
              // }, 1000);
              localStorage.removeItem("info");
            } else {
              that.$message.error(res.data.msg);
              // this.$router.push({path:'/login',query: { islogin:0 }})
            }
          });
        } else {
          that.$message.error(
            "请按照要求格式填写（除“理事代表信息”外，其他为必填项）"
          );
          return false;
        }
      });
    },
    //修改-获取信息
    getEditMember() {
      this.Loading(".form");
      getEditMember().then((res) => {
        this.isloading.close();
        console.log("res", res);
        this.$set(this, "ruleForm", res.data.data);
        // this.$set(this, "ruleForm2", res.data.data);
        this.ruleForm2 = res.data.data;
        this.oldcompany = res.data.data.company;
        this.yyzzfile = res.data.data.file;
        this.id = res.data.data.id;
        let createtime = this.dateFormat(res.data.data.create_time);
        let hybirthday = this.dateFormat(res.data.data.hy_birthday);
        this.create_time = createtime;
        this.hy_birthday = hybirthday;
        this.revenue_year = res.data.data.revenue_year;
        let str = res.data.data.speciality;
        if (str == 0) {
          this.speciality = "";
        } else {
          this.speciality = str.split(",").map(Number);
        }
        let str2 = res.data.data.belong_department;
        if (str2 == 0) {
          this.ruleForm.belong_department = "";
        } else {
          this.ruleForm.belong_department = str2;
        }
        // console.log('11111111',res.data.data.file)
        // if (res.data.data.pay_file) {
        //   this.fileList2 = [{ url: res.data.data.pay_file }];
        // }
        if (res.data.data.file) {
          this.fileList = [{ url: res.data.data.file }];
        }
        if (res.data.data.word_file) {
          this.fileList3 = [{ url: res.data.data.word_file }];
        }
        localStorage.removeItem("info");
        // console.log('11111111',this.fileList)
      });
    },
    //是否与法人代表相同
    samechange(e) {
      // console.log('11111111111',e.target.checked)
      let ischeck = e.target.checked;
      this.sameAsLegalRep = e.target.checked;
      if (ischeck) {
        this.ruleForm.hy_name = this.ruleForm.fr_name;
        this.ruleForm.hy_type = this.ruleForm.fr_type;
        this.ruleForm.hy_nationality = this.ruleForm.fr_nationality;
        this.ruleForm.hy_job = this.ruleForm.fr_job;
        this.ruleForm.hy_phone = this.ruleForm.fr_phone;
        this.ruleForm.hy_email = this.ruleForm.fr_email;
      } else {
        this.ruleForm.hy_name = "";
        this.ruleForm.hy_type = "";
        this.ruleForm.hy_nationality = "";
        this.ruleForm.hy_job = "";
        this.ruleForm.hy_phone = "";
        this.ruleForm.hy_email = "";
      }
    },
    //注册地址-省市区
    handleChange(value) {
      // console.log('value',value);
      (this.ruleForm.province = value[0]),
        (this.ruleForm.city = value[1]),
        (this.ruleForm.area = value[2]);
      // console.log("province", this.province);
    },
    //邮寄地址-省市区
    handleChange2(value) {
      (this.ruleForm.email_province = value[0]),
        (this.ruleForm.email_city = value[1]),
        (this.ruleForm.email_area = value[2]);
    },
    convertToTimestamp(dateString) {
      const date = new Date(dateString);
      const timestamp = date.getTime() / 1000;
      return timestamp;
    },
    moreselect(e) {
      // console.log("多选", e);
      let str = e.join(",");
      // console.log("多选", str);
      this.ruleForm.speciality = str;
    },
    //时间戳 /1000
    createhange(e) {
      // console.log("成立时间", e);
      let time = this.convertToTimestamp(e);
      this.ruleForm.create_time = time;
    },
    birchange(e) {
      // console.log("出生日期", e);
      let time = this.convertToTimestamp(e);
      this.ruleForm.hy_birthday = time;
    },
    yearchange(e) {
      console.log(e);
      const date = new Date(e);
      const year = date.getFullYear();
      this.ruleForm.revenue_year = year;
    },
    getOtherSelect() {
      getOtherSelect().then((res) => {
        // console.log('归口部门/专业领域',res)
        if (res.data.code == 1) {
          this.departmentArr = res.data.data.department;
          this.specialityArr = res.data.data.speciality;
        }
      });
    },
    submitForm(formName) {
      let that = this;
      // console.log('555555',that.type)
      if (that.type == 0) {
        that.$set(that.ruleForm, "id", that.id);
        const {
          add_time_text,
          add_time,
          reason,
          end_time,
          end_time_text,
          hy_level,
          is_disable,
          status,
          status_name,
          check_time_text,
          create_time_text,
          check_time,
          ...newObjj
        } = that.ruleForm;
        that.ruleForm = newObjj;
        if ((newObjj.company != that.oldcompany) && (newObjj.file == that.yyzzfile) ) {
          this.$message({
            duration: 6000,
            type: "error",
            message: "请上传贵单位新的统一社会信用代码证（或营业执照）扫描件",
          });
        } else {
          that.$refs[formName].validate((valid) => {
            if (valid) {
              that.Loading(".form");
              let newObj = that.ruleForm;
              newObj["user"] = localStorage.getItem("uid");
              memberApply({
                ...newObj,
              }).then((res) => {
                that.isloading.close();
                // console.log('入会申请',res)
                if (res.data.code == 1) {
                  // that.$message.success(res.data.msg);
                  that.$message.success("提交成功");
                  if (that.type == 0) {
                  } else {
                    setTimeout(() => {
                      that.ruleForm = {};
                      that.$router.push("/success");
                    }, 1000);
                  }
                  localStorage.removeItem("info");
                } else {
                  that.$message.error(res.data.msg);
                }
              });
            } else {
              that.$message.error(
                "请按照要求格式填写（除“理事代表信息”外，其他为必填项）"
              );
              return false;
            }
          });
        }
        // console.log("1111111111", newObj);
      } else {
        that.$refs[formName].validate((valid) => {
          if (valid) {
            that.Loading(".form");
            let newObj = that.ruleForm;
            newObj["user"] = localStorage.getItem("uid");
            memberApply({
              ...newObj,
            }).then((res) => {
              that.isloading.close();
              // console.log('入会申请',res)
              if (res.data.code == 1) {
                // that.$message.success(res.data.msg);
                that.$message.success("提交成功");
                if (that.type == 0) {
                } else {
                  setTimeout(() => {
                    that.ruleForm = {};
                    that.$router.push("/success");
                  }, 1000);
                }
                localStorage.removeItem("info");
              } else {
                that.$message.error(res.data.msg);
              }
            });
          } else {
            that.$message.error(
              "请按照要求格式填写（除“理事代表信息”外，其他为必填项）"
            );
            return false;
          }
        });
      }
      // return;

      // this.$router.push(`./success`);
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleRemove(file, fileList) {
      // console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    //成功
    handleAvatarSuccess(res, file) {
      this.ruleForm.file = res.data.fullurl;
      this.$message.success("上传成功");
      this.loading = false;
    },
    //上传时
    handleUploadProgress(event) {
      this.loading = true;
    },
    handleAvatarError(res, file) {
      this.$message.error("上传失败");
      this.loading = false;
    },
    //成功
    // handleAvatarSuccess2(res, file) {
    //   this.ruleForm.pay_file = res.data.fullurl;
    //   this.$message.success("上传成功");
    //   this.loading2 = false;
    // },
    //上传时
    handleUploadProgress2(event) {
      this.loading2 = true;
    },
    handleAvatarError2(res, file) {
      this.$message.error("上传失败");
      this.loading2 = false;
    },
    //成功
    handleAvatarSuccess3(res, file) {
      const fileName = file.name;
      const fileExtension = fileName.split(".").pop().toLowerCase();

      const imageExtensions = ["jpg", "jpeg", "png", "gif"];
      const documentExtensions = ["txt", "doc", "docx", "pdf"];

      // console.log("666666666666", file.name);
      if (imageExtensions.includes(fileExtension)) {
      } else {
        this.fileList3 = [
          { url: "https://www.ic-ceca.org.cn/uploads/Defaultimg/image.png" },
        ];
      }
      this.ruleForm.word_file = res.data.fullurl;
      this.$message.success("上传成功");
      this.loading3 = false;
    },
    //上传时
    handleUploadProgress3(event) {
      this.loading3 = true;
    },
    handleAvatarError3(res, file) {
      this.$message.error("上传失败");
      this.loading3 = false;
    },
  },
};
</script>
  
  <style lang="scss">
.form {
  width: 100%;
  //   border: 1px solid red;
  margin-top: 40px;
  .title {
    font-size: 26px;
    margin-bottom: 15px;
    margin-top: 10px;
  }
  .center {
    // text-align: center;
  }
  .bg {
    background-color: rgba(255, 255, 255, 0.69);
    border-radius: 5px;
    padding-top: 10px;
    padding-left: 25px;
    width: 93%;
  }
  /deep/.width-25 {
    width: 25%;
    margin-right: 0 !important;
  }
  .el-input--suffix .el-input__inner {
    width: 100%;
    margin-right: 0 !important;
  }
  .el-radio-group {
    margin: 20px 10px;
  }
  .el-radio {
    padding: 10px 5px;
  }
  .el-form--inline .el-form-item {
    width: 29%;
    margin-right: calc(100vw * 49 / 1920);
    // margin: 0 auto;
  }
  .el-form--label-top .el-form-item__label {
    padding: 0;
    color: #000000;
    font-size: 18px;
  }
  .el-button--primary {
    width: 30%;
    margin: 40px auto;
    height: 50px;
    margin-left: 22vw;
    // text-align: center;
  }
}
</style>