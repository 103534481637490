<template>
	<div class="box">
		<div class="sidebar">
			<sidebar></sidebar>
		</div>
		<div class="quit">
			<Header></Header>
			<div class="quit_cont">
				<div class="title">退会申请</div>
				<div class="explain">
					<div class="text"><span>*</span>退会说明</div>
					<el-input type="textarea" class="textarea" :rows="8" placeholder="请输入您的退会说明" v-model="reason">
					</el-input>
				</div>
				<div class="uploading" v-loading="loading">
					<div class="text"><span>*</span>附件上传</div>
					<el-upload action="https://www.ic-ceca.org.cn/api/common/upload"
						:headers="{ token: token, __token__: token }" list-type="picture-card"
						:on-success="handleAvatarSuccess" :on-error="handleAvatarError">
						<i slot="default" class="el-icon-plus"></i>
						<div slot-scope="{ file }">
							<img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
							<span class="el-upload-list__item-actions">
								<span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
									<i class="el-icon-zoom-in"></i>
								</span>
								<span v-if="!disabled" class="el-upload-list__item-delete"
									@click="handleDownload(file)">
									<i class="el-icon-download"></i>
								</span>
								<span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
									<i class="el-icon-delete"></i>
								</span>
							</span>
						</div>
					</el-upload>
					<span style="margin-left: 20px;font-size: 13px;">请上传加盖公章的退会申请书</span>
					<el-dialog :visible.sync="dialogVisible">
						<img width="100%" :src="dialogImageUrl" alt="" />
					</el-dialog>
				</div>
				<div class="submit" @click="outApply">提交审核</div>
			</div>
			<Footer></Footer>
		</div>
	</div>
</template>

<script>
	import {
		outApply
	} from "../api/index";
	import Header from "../components/header2.vue";
	import Footer from "../components/footer2.vue";
	import sidebar from "../components/sidebar.vue";
	export default {
		components: {
			Header,
			Footer,
			sidebar,
		},
		data() {
			return {
				loading: false,
				token: localStorage.getItem("token"),
				reason: "",
				dialogImageUrl: "",
				dialogVisible: false,
				disabled: false,
				file: "",
			};
		},
		methods: {
			outApply() {
				this.Loading(".submit");
				outApply({
					reason: this.reason,
					file: this.file,
				}).then((res) => {
					this.isloading.close();
					if (res.data.code == 1) {
						(this.reason = ""),
						(this.file = ""),
						this.$message.success(res.data.msg);
					} else {
						this.$message.error(res.data.msg);
					}
				});
			},
			handleRemove(file) {
				// console.log(file);
			},
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			handleDownload(file) {
				// console.log(file);
			},
			handleAvatarSuccess(res, file) {
				this.file = res.data.fullurl;
				this.$message.success("上传成功");
				this.loading = false;
			},
			handleAvatarError(res, file) {
				this.$message.error("上传失败");
			},
			//上传时
			handleUploadProgress(event) {
				this.loading = true;
			},
		},
	};
</script>

<style scoped lang="scss">
	body {
		padding: 0;
		margin: 0;
	}

	.box {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	.sidebar {
		width: 18%;
	}

	.quit {
		width: 82%;
		background: linear-gradient(180deg,
				rgba(171, 198, 246, 0.27) 0%,
				rgba(216, 224, 232, 0.1) 100%);
	}

	.quit_cont {
		height: auto;
		border-radius: 5px;
		background: #fff;
		margin: 35px 72px 91px 82px;
		padding: 44px 101px 60px 101px;
		position: relative;
		padding-bottom: 48px;
	}

	.quit_cont .title {
		text-align: center;
		color: #000;
		text-align: center;
		font-size: 24px;
		font-weight: 500;
	}

	.quit_cont .explain {
		margin-top: 60px;
	}

	.quit_cont .explain .text {
		color: #000;
		font-size: 18px;
		margin-bottom: 15px;
	}

	.quit_cont .explain .text span {
		color: #de0000;
	}

	.quit_cont .explain .textarea {
		color: #aaa;
		font-size: 18px;
	}

	.quit_cont .uploading {
		margin-top: 32px;
		display: flex;
	}

	.quit_cont .uploading .text {
		color: #000;
		font-size: 18px;
		margin-bottom: 15px;
		margin-right: 16px;
	}

	.quit_cont .uploading .text span {
		color: #de0000;
	}

	.quit_cont .submit {
		width: 334px;
		height: 50px;
		line-height: 50px;
		text-align: center;
		flex-shrink: 0;
		color: #fff;
		text-align: center;
		font-size: 18px;
		font-weight: 500;
		border-radius: 5px;
		background: #1890ff;
		margin: 0 auto;
		margin-top: 57px;
		cursor: pointer;
	}

	@media only screen and (max-width: 1800px) {
		.quit_cont .title {
			text-align: center;
			color: #000;
			text-align: center;
			font-size: 20px;
			font-weight: 500;
		}

		.quit_cont .explain .text {
			color: #000;
			font-size: 16px;
			margin-bottom: 15px;
		}

		.quit_cont .uploading .text {
			color: #000;
			font-size: 16px;
			margin-bottom: 15px;
			margin-right: 16px;
		}
	}

	@media only screen and (max-width: 1600px) {
		.quit_cont {
			height: auto;
			margin: 30px 30px 0;
		}

		.quit_cont .explain {
			margin-top: 10px;
		}
	}

	@media only screen and (max-width: 1500px) {
		.quit_cont {
			padding: 30px;
		}

		.el-textarea__inner {
			display: block;
			resize: vertical;
			padding: 5px 15px;
			line-height: 1.5;
			height: 110px;
			box-sizing: border-box;
			width: 100%;
			font-size: inherit;
			color: #606266;
			background-color: #fff;
			background-image: none;
			border: 1px solid #dcdfe6;
			border-radius: 4px;
			transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
		}

		.el-upload--picture-card {
			background-color: #fbfdff;
			border: 1px dashed #c0ccda;
			border-radius: 6px;
			box-sizing: border-box;
			width: 100px;
			height: 100px;
			cursor: pointer;
			line-height: 100px;
			vertical-align: top;
		}
	}

	@media only screen and (max-width: 768px) {
		.box {
			.sidebar {
				width: 0;
			}

			.quit {
				width: 100%;

				.quit_cont {
					.submit {
						width: auto;
					}
				}
			}
		}

		.quit_cont {
			padding: 10px;
			margin: 10px 10px 0;
		}

		.quit_cont .title {
			text-align: center;
			color: #000;
			text-align: center;
			font-size: 18px;
			font-weight: 500;
		}

		.quit_cont .explain .textarea {
			color: #aaa;
			font-size: 14px;
		}

		.quit_cont .uploading {
			margin-top: 10px;
			display: flex;
		}

		.el-upload--picture-card {
			background-color: #fbfdff;
			border: 1px dashed #c0ccda;
			border-radius: 6px;
			box-sizing: border-box;
			width: 120px;
			height: 120px;
			cursor: pointer;
			line-height: 120px;
			vertical-align: top;
		}

		.quit_cont .submit {
			width: 334px;
			height: 40px;
			line-height: 40px;
			text-align: center;
			flex-shrink: 0;
			color: #fff;
			text-align: center;
			font-size: 16px;
			font-weight: 500;
			border-radius: 5px;
			background: #1890ff;
			margin: 0 auto;
			margin-top: 30px;
			cursor: pointer;
		}

		/deep/.el-textarea__inner {
			min-height: 30px !important;
		}
	}
</style>